import React from 'react'

import { StepWrapper, HalfStep } from "../Section"
import StepVerticalVar from "../Icons/StepVerticalVar"
import HalfStepBar from "../Icons/HalfStepBar"

const FirstStepBar = (props) => {
	return(
		<StepWrapper top="280px" color={props.color}>
      <div>
        <strong className="step-number" style={{color: props.fill}}>1</strong>
        <span className='step-label' style={{color: props.fill}}>STEP</span>
        <HalfStep><HalfStepBar fill={props.fill} /></HalfStep>
        <StepVerticalVar fill="#010920"/>
      </div>
      <div>
        <strong className="step-number">2</strong>
        <span className='step-label'>STEP</span>
        <StepVerticalVar fill="#010920"/>
      </div>
      <div>
        <strong className="step-number">3</strong>
        <span className='step-label'>STEP</span>
      </div>
    </StepWrapper>
	)
}

export default FirstStepBar